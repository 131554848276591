
import type { Student } from "@/interfaces/api";
import { PropType } from "vue";
import { Options, Vue } from "vue-class-component";

@Options({
  props: {
    /**
     * The students
     */
    students: {
      type: Array as PropType<Student[]>,
      required: false,
    },
  },
  data: () => ({
    /**
     * If the details is shown
     */
    shown: false,
  }),
  methods: {
    /**
     * Gets called when the user clicks on the arrow
     */
    toggle() {
      this.shown = !this.shown;
    },
  },
})
export default class Accordion extends Vue {}
